import axios from 'axios';
import { IUser } from '@sravni/types/lib/auth';
import {
    ImportUserParams,
    ImportUserResponse,
    SignInParams,
    SignInResponse,
    VerifyParams,
    VerifyResponse,
} from '../../services/identity/identity.types';
import { ISuggestLocationsParams } from '../../services/location/location.types';
import { Model, Brand, ModelPowersProps, CarInfo } from '../../services/auto/auto.types';
import { TaskRequestData, ScoringDataResponse } from '../../services/scoring/scoring.types';
import {
    PostScoreGen7Response,
    GetScoreGen7Response,
    UserData,
} from '../../services/amazingScoring/amazingScoring.types';
import { ModelsResponse } from '../../services/generalScorer/generalScorer.types';
import { CallCenter } from '../../server/api/auth/types/callcenter';
import { CaptchaClient } from '../captcha';

export type UserDataParams = Omit<UserData, 'userId'>;

export class ClientProvider {
    constructor(protected path: string) {}

    locationSuggest = async (params: ISuggestLocationsParams): Promise<Array<Location.SuggestItem>> => {
        const { data } = await axios.get<Array<Location.SuggestItem>>(`${this.path}/suggest/location/`, {
            params,
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        return data;
    };

    locationForRoute = async (address: string): Promise<Location.Item | null> => {
        const { data } = await axios.get<Location.Item | null>(`${this.path}/location/`, {
            params: { address },
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        return data;
    };

    locationSearchById = async (id: number): Promise<[Location.SuggestItem]> => {
        const { data } = await axios.get<[Location.SuggestItem]>(`${this.path}/location/search/`, {
            params: { ids: id },
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        return data;
    };

    locationSearchByRoute = async (route: string): Promise<[Location.SuggestItem]> => {
        const { data } = await axios.get<[Location.SuggestItem]>(`${this.path}/location/search/`, {
            params: { route },
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        return data;
    };

    /** with captcha v3 */
    signIn = async (params: SignInParams): Promise<SignInResponse> => {
        const captcha = await CaptchaClient.execute('signIn');
        const { data } = await axios.post<SignInResponse>(`${this.path}/auth/signin/`, {
            ...params,
            captcha,
        });
        return data;
    };

    callCenterSignIn = async (params: CallCenter.SignInParams): Promise<boolean> => {
        const { data } = await axios.post<{ success: boolean }>(`${this.path}/auth/callcenter/`, params);
        return data.success;
    };

    verify = async (params: VerifyParams): Promise<VerifyResponse> => {
        const { data } = await axios.post<VerifyResponse>(`${this.path}/auth/verify/`, params);
        return data;
    };

    signInSilent = async (params: ImportUserParams): Promise<ImportUserResponse> => {
        const { data } = await axios.post<ImportUserResponse>(`${this.path}/auth/silent/`, params);
        return data;
    };

    account = async (): Promise<IUser | null> => {
        const { data } = await axios.get<IUser | null>(`${this.path}/auth/account/`, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            withCredentials: true,
        });
        return data;
    };

    carBrands = async () => {
        const { data } = await axios.post<Array<Brand>>(`${this.path}/suggest/car-brands/`);
        return data;
    };

    carModels = async (params: { brandId: number | string }) => {
        const { data } = await axios.post<Array<Model>>(`${this.path}/suggest/car-models/`, params);
        return data;
    };

    carModelYears = async (params: { modelId: number | string }) => {
        const { data } = await axios.post<Array<number>>(`${this.path}/suggest/car-model-years/`, params);
        return data;
    };

    carModelPowers = async (params: ModelPowersProps) => {
        const { data } = await axios.post<Array<number>>(`${this.path}/suggest/car-model-powers/`, params);
        return data;
    };

    /** with captcha v3 */
    carInfoByNumber = async (params: { carNumber: string }) => {
        const captcha = await CaptchaClient.execute('carInfoByNumber');
        const { data } = await axios.post<CarInfo | null>(`${this.path}/suggest/car-info-by-number/`, {
            ...params,
            captcha,
        });
        return data;
    };

    scoringTask = async (params: TaskRequestData): Promise<string | null> => {
        const { data } = await axios.post<{ id: string | null }>(`${this.path}/scoring/task/`, params);
        return data.id;
    };

    scoring = async (taskId: string): Promise<ScoringDataResponse> => {
        const { data } = await axios.post<ScoringDataResponse>(`${this.path}/scoring/data/`, { taskId });
        return data;
    };

    postScoringGen7 = async (userData: UserDataParams, userId?: string) => {
        const { data } = await axios.post<PostScoreGen7Response>(
            `${this.path}/scoring/gen7/`,
            { userData },
            { params: { userId } },
        );
        return data;
    };

    checkScoringGen7 = async (id: string) => {
        const { data } = await axios.get<GetScoreGen7Response>(`${this.path}/scoring/gen7/`, { params: { id } });
        return data;
    };

    /** Получает предодобренный лимит по кредитной карте */
    getModelsScore = async (params: FE.FormData): Promise<ModelsResponse> => {
        const { data } = await axios.post<ModelsResponse>(`${this.path}/scorerModels/getModelsScore/`, params);
        return data;
    };
}
